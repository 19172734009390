(function (angular) {
    angular.module('MyHippoProducer.Controllers').controller('NotificationListController', NotificationListController);
    NotificationListController.$inject = ['$log', '$scope', '$state', '$timeout', 'APIService'];
    function NotificationListController ($log, $scope, $state, $timeout, APIService) {
        $log.info('NotificationListController');

        $scope.currentPage = 1;
        $scope.itemsPerPage = 20;
        $scope.filters = {};

        $scope.findNotifications = function () {
            $scope.filters.offset = ($scope.currentPage - 1) * $scope.itemsPerPage;
            $scope.filters.limit = parseInt($scope.itemsPerPage, 10);

            APIService.findNotifications($scope.filters).then(function (results) {
                $scope.notifications = results.notifications;
                $scope.totalItems = results.count;

                if ($scope.notifications.length) {
                    APIService.markNotificationsRead({
                        lastNotificationTs: $scope.notifications[0].data.published_ts
                    });
                }
            });
        };
        $scope.findNotifications();

        $scope.filterNotifications = function () {
            $scope.currentPage = 1;
            $scope.findNotifications();
        };

        $scope.updateFavorite = function (notification, favorite) {
            APIService.updateNotificationFavorite(notification.id, favorite).then(function (response) {
                $log.info('updateFavorite', response);
                if (response) {
                    notification.recipient.favorite = favorite;
                }
            });
        };
    }
})(window.angular);
